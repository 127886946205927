// Generated by Framer (b62c1c2)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, SmartComponentScopedContainer, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import { Video } from "https://framerusercontent.com/modules/lRDHiNWNVWmE0lqtoVHP/7qT0r3So12155VV5Jq5x/Video.js";
const VideoFonts = getFonts(Video);

const cycleOrder = ["r8Kcv5Tob", "vNyzI8iGz"];

const serializationHash = "framer-2IYpt"

const variantClassNames = {r8Kcv5Tob: "framer-v-1yody5s", vNyzI8iGz: "framer-v-1pvyier"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "r8Kcv5Tob", "Variant 2": "vNyzI8iGz"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "r8Kcv5Tob"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "r8Kcv5Tob", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapyw9ojd = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("vNyzI8iGz")
})

const onMouseEnterrkowpu = activeVariantCallback(async (...args) => {
setVariant("vNyzI8iGz")
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1yody5s", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"r8Kcv5Tob"} onTap={onTapyw9ojd} ref={refBinding} style={{...style}} {...addPropertyOverrides({vNyzI8iGz: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-r0uebu-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"QsEVNRdXw-container"} nodeId={"QsEVNRdXw"} rendersWithMotion scopeId={"f7sgS1E3N"}><Video backgroundColor={"rgba(0, 0, 0, 0)"} borderRadius={10} bottomLeftRadius={10} bottomRightRadius={10} controls={false} height={"100%"} id={"QsEVNRdXw"} isMixedBorderRadius={false} layoutId={"QsEVNRdXw"} loop muted objectFit={"cover"} onMouseEnter={onMouseEnterrkowpu} playing poster={"https://framerusercontent.com/images/bJHSnSOvRi1YWjxTvguc1riS4L4.jpg"} posterEnabled srcFile={"https://framerusercontent.com/assets/JUamYnN1Wn4Nj2jB4runZxsbkNw.mp4"} srcType={"Upload"} srcUrl={"https://framerusercontent.com/assets/MLWPbW1dUQawJLhhun3dBwpgJak.mp4"} startTime={0} style={{height: "100%", width: "100%"}} topLeftRadius={10} topRightRadius={10} volume={25} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2IYpt.framer-1hvg3q6, .framer-2IYpt .framer-1hvg3q6 { display: block; }", ".framer-2IYpt.framer-1yody5s { cursor: pointer; height: 236px; overflow: visible; position: relative; width: 372px; }", ".framer-2IYpt .framer-r0uebu-container { flex: none; height: 100%; left: calc(50.00000000000002% - 100% / 2); position: absolute; top: calc(50.00000000000002% - 100% / 2); width: 100%; }", ".framer-2IYpt.framer-v-1pvyier.framer-1yody5s { height: 432px; width: 681px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 236
 * @framerIntrinsicWidth 372
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"vNyzI8iGz":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerf7sgS1E3N: React.ComponentType<Props> = withCSS(Component, css, "framer-2IYpt") as typeof Component;
export default Framerf7sgS1E3N;

Framerf7sgS1E3N.displayName = "video";

Framerf7sgS1E3N.defaultProps = {height: 236, width: 372};

addPropertyControls(Framerf7sgS1E3N, {variant: {options: ["r8Kcv5Tob", "vNyzI8iGz"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerf7sgS1E3N, [{explicitInter: true, fonts: []}, ...VideoFonts], {supportsExplicitInterCodegen: true})